import React, { useMemo } from 'react';
import { graphql } from 'gatsby';
import classNames from 'classnames';
import { dayjs } from '../../../../lib/dayjs';
import { isNewsTypeColor } from '../../../../lib/is-news-type-color';
import { Layout } from '../../../../components/layout';
import { BreadCrumb } from '../../../../components/breadcrumb';
import { BackGround } from '../../../../components/background';
import { Seo } from '../../../../components/seo';
import { SeasonHeader } from '../../../../components/season-header';
import * as Styles from '../../../../styles/pages/_seasonId.module.scss';
import * as CommonStyles from '../../../../styles/global/_common.module.scss';
import {Link, useIntl, navigate} from "gatsby-plugin-react-intl"

import Flag from '/static/flag.svg';
import Cup from '/static/cup.svg';
import { contentPathHelper } from '../../../../utils/contentPathHelper';
import { useCheerio } from '../../../../hooks/use-cheerio';

const EVENT_TYPE = {
  shingle: '0',
  exhibition: '1',
  tournament: '2',
};

// 数字の頭の0を削除する
const suppressZero = (str) => {
  let idx = 0;
  while (str.charAt(idx) === '0') idx++;
  return str.slice(idx);
};

const eventReordering = (data, years) => {
  const yearsSort = [];

  years.forEach((year) => {
    // 年ごとに要素を抽出して配列を作成
    const yearArray = data.filter(({ node }) => dayjs(node.date).format('YYYY') === year);

    // 配列から月の一覧を抽出
    const months = Array.from(new Set(yearArray.map(({ node }) => dayjs(node.date).format('MM'))));

    const monthSort = [];
    months.forEach((month) => {
      // 月ごとに要素を抽出して配列を作成
      const monthArray = yearArray.filter(({ node }) => dayjs(node.date).format('MM') === month);

      monthSort.push({
        month: suppressZero(month),
        items: monthArray,
      });
    });
    yearsSort.push({
      year: year,
      items: monthSort,
    });
  });
  return yearsSort;
};

const SeasonSinglePage = ({ data, location }) => {
  const intl = useIntl()
  const t = (id) => intl.formatMessage({id})
  const { microcmsSeason, microcmsEvent, allMicrocmsSeason, allMicrocmsMatch, allMicrocmsNews } = data;

  const $ = useCheerio(microcmsSeason?.rule)
  const isRuleText = useMemo(() => !!$.text(), [$])

  React.useEffect(() => {
    if (!microcmsSeason) {
      navigate(`/news/${microcmsEvent.eventId}`)
    }
  }, [microcmsSeason])

  const postDescription = microcmsEvent.description?.replace(/(<([^>]+)>)/gi, '').substr(0, 70);

  const eventLabel = microcmsSeason?.events.filter((event) => {
    return event.events.id === microcmsEvent.eventId;
  });

  const years = Array.from(new Set(allMicrocmsMatch.edges.map(({ node }) => dayjs(node.date).format('YYYY'))));

  const [age] = React.useState(years[0]);

  const eventItems = eventReordering(allMicrocmsMatch.edges, years);

  const relatedArticles = () => {
    if (!microcmsSeason.relation.length) {
      return [];
    }

    return allMicrocmsNews.edges.filter(({ node }) => {
      let check = false;

      node.relation.forEach((relation) => {
        microcmsSeason.relation.forEach(({ id }) => {
          if (relation.id === id) {
            check = true;
          }
        });
      });

      return check;
    });
  };

  return (
    <Layout location={location}>
      {microcmsSeason && (
        <>
          <BreadCrumb
            list={[
              {
                title: 'TOP',
                link: '/',
              },
              {
                title: t('menu.seasonInformation')
              },
              {
                title: microcmsSeason?.title,
                link: '/season/' + microcmsSeason?.seasonId + '/',
              },
              {
                title: eventLabel[0].label,
                link: '/season/' + microcmsEvent.eventId + '/event/',
              },
            ]}
            currentPage={t('season.matchSchedule')}
          />

          <Seo title={`${eventLabel ? eventLabel[0].label : microcmsEvent.title} | ${microcmsSeason.title}${t('season.seo.title')}`} description={postDescription} />
        </>
      )}
      <BackGround>
        {microcmsSeason && <SeasonHeader currentSeason={microcmsSeason} seasonList={allMicrocmsSeason.edges} linkList={microcmsSeason.events} location={location} isSeasonRule={isRuleText} />}
        <section className={Styles.seasonId}>
          <div className={Styles.seasonId__wrapper}>
            <div className={classNames(CommonStyles.container)}>
              <h2 className={Styles.seasonId__header}>{microcmsEvent.title}</h2>

              <div className={Styles.seasonId__thumbnail}>
                <img src={microcmsEvent.image.url + '?w=1660&q=80'} alt={microcmsEvent.title} />
              </div>

              <div
                className={Styles.seasonId__description}
                dangerouslySetInnerHTML={{
                  __html: microcmsEvent.description,
                }}
              />

              <ul id="menu-list" className={Styles.seasonId__menu_list}>
                <li>
                  <Link className={Styles.seasonId__menu_list__current} to={`/season/event/${contentPathHelper(microcmsEvent.eventId)}#menu-list/`}>
                    {t('season.matchSchedule')}
                  </Link>
                </li>
                <li>
                  <Link to={`/season/event/${contentPathHelper(microcmsEvent.eventId)}/result#menu-list/`}>{t('season.matchResult')}</Link>
                </li>

                {microcmsEvent.rule && (
                  <li>
                    <Link to={`/season/event/${contentPathHelper(microcmsEvent.eventId)}/rule#menu-list/`}>{t('season.rules')}</Link>
                  </li>
                )}

                {allMicrocmsMatch.edges.length !== 0 && microcmsEvent.ranking && (
                  <li>
                    <Link to={`/season/event/${contentPathHelper(microcmsEvent.eventId)}/ranking#menu-list/`}>{t('season.ranking')}</Link>
                  </li>
                )}

                {microcmsEvent.tournament && (
                  <li>
                    <Link to={`/season/event/${contentPathHelper(microcmsEvent.eventId)}/tournament#menu-list/`}>{t('season.tournament')}</Link>
                  </li>
                )}
              </ul>

              {eventItems[years.indexOf(age)] && (
                <ul className={Styles.month_list}>
                  {eventItems[years.indexOf(age)].items.map(({ month, items }, i) => (
                    <li key={i}>
                      <h2>
                        <span>{month}</span>
                        <small>{dayjs(month).locale('en').format('MMM')}</small>
                      </h2>
                      <ul className={Styles.event_list}>
                        {items.map(({ node }) => (
                          <li key={node.matchId}>
                            <Link to={`/match/${contentPathHelper(node.matchId)}/`} className={Styles.event_list__item}>
                              {/* イベント種別ごとのアイコンを設定してください */}
                              {node.event.type[0] === EVENT_TYPE.shingle && <img src={Flag} className="flag" alt="flag" />}
                              {node.event.type[0] === EVENT_TYPE.exhibition && <img src={Cup} className="cup" alt="cup" />}
                              {node.event.type[0] === EVENT_TYPE.tournament && <img src={Cup} className="cup" alt="cup" />}
                              <time>{dayjs(node.date).format('D (dd)')}</time>
                              <span>
                                {node.event.title} {node.title}
                              </span>
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </li>
                  ))}
                </ul>
              )}
              {eventItems.length === 0 && (
                <div>
                  {t('season.noContentsSchedule')}
                </div>
              )}

              <h2 className={Styles.seasonId__accent_heading}>関連記事</h2>

              {microcmsSeason !== null ? (
                <ul className={Styles.seasonId__list}>
                  {relatedArticles()
                    .slice(0, 3)
                    .map(({ node }) => (
                      <li key={node.seasonId} className={Styles.seasonId__list_card}>
                        <Link to={`/news/${contentPathHelper(node.newsId)}/`}>
                          <img src={node.image.url} alt="" />
                          <div className={Styles.seasonId__boxwrap}>
                            <div className={Styles.seasonId__set}>
                              {dayjs(node.publishedAt).format('YYYY/MM/DD')}{' '}
                              <p className={Styles.seasonId__newscategory} style={{ backgroundColor: isNewsTypeColor(node.type[0]) }}>
                                {node.type[0]}
                              </p>
                            </div>
                            <p className={Styles.seasonId__newstitle}>{node.title}</p>
                          </div>
                        </Link>
                      </li>
                    ))}
                </ul>
              ) : (
                <p>{t('season.noArticles')}</p>
              )}
            </div>
          </div>
        </section>
      </BackGround>
    </Layout>
  );
};

export const query = graphql`
  query ($eventId: String) {
    microcmsEvent(eventId: { eq: $eventId }) {
      eventId
      publishedAt
      date
      in_date
      out_date
      title
      image {
        url
      }
      description
      participant {
        id
        image {
          url
        }
        name
        user_id
      }
      news_relation {
        id
        publishedAt
        revisedAt
        description
      }
      about {
        fieldId
        description
        prize
        win_lose
        ohter_field {
          fieldId
          title
          content
        }
        judge_field {
          fieldId
          image {
            url
          }
          title
          name
        }
      }
      rule
      concretes {
        fieldId
        theme
      }
      abstracts {
        fieldId
        theme
      }
      tournament {
        url
      }
      ranking
    }

    allMicrocmsMatch(filter: { event: { id: { eq: $eventId } } }) {
      edges {
        node {
          matchId
          title
          date
          event {
            id
            title
            type
            news_relation {
              id
            }
          }
          red {
            id
            name
            image_url
            image {
              url
            }
          }
          red_point
          blue {
            id
            name
            image_url
            image {
              url
            }
          }
          blue_point
          concrete
          abstract
          video_id
          publishedAt
          winner
        }
      }
    }

    microcmsSeason(events: { elemMatch: { events: { id: { eq: $eventId } } } }) {
      seasonId
      title
      publishedAt
      about
      rule
      relation {
        id
      }
      events {
        fieldId
        label
        events {
          date
          description
          id
          abstracts {
            fieldId
            theme
          }
          about {
            fieldId
            judge_field {
              fieldId
              image {
                url
              }
              name
              title
            }
            ohter_field {
              content
              fieldId
              title
            }
            prize
            win_lose
          }
          concretes {
            fieldId
            theme
          }
          image {
            url
          }
          publishedAt
          out_date
          in_date
          news_relation {
            id
          }
          participant {
            id
          }
          tournament {
            url
          }
          title
          rule
          type
        }
      }
    }

    allMicrocmsSeason(filter: {is_private: {eq: false}}) {
      edges {
        node {
          seasonId
          title
        }
      }
    }

    allMicrocmsNews(sort: { fields: [publishedAt], order: ASC }) {
      edges {
        node {
          newsId
          image {
            url
          }
          title
          publishedAt
          type
          relation {
            id
            description
          }
        }
      }
    }
  }
`;

export default SeasonSinglePage;
